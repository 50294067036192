<template>
  <el-container>
    <el-main style="padding:0;width:100vh">
      <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="elevators/passport">
        <template slot="adSearch">
          <vm-search :label="$t('maintWorkOrder.filter')">
            <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('elevator.elevatorType', '梯种')">
            <el-select
              v-model="search.productTypeId"
              :style="{ width: $l('250px', '150px') }"
              :placeholder="$t('elevator.elevatorType')"
              clearable>
              <el-option
                v-for="item in productTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$l('elevator.onlineStatus', '在线状态')">
            <el-select
              v-model="search.onlineStatus"
              :placeholder="$t('elevator.onlineStatus')"
              :style="{ width: $l('150px', '100px') }"
              clearable>
              <el-option
                v-for="item in onlineStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <vm-search :label="$l('elevator.joinStatus', '入网状态')">
            <el-select
              v-model="search.inNetState"
              :placeholder="$t('elevator.joinStatus')"
              :style="{ width: $l('150px', '100px') }"
              clearable>
              <el-option
                v-for="item in inNetStateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
        </template>
        <el-table-column prop="no" :label="$t('elevator.no')" width="120" align="center">
          <template v-slot="scope">
            <router-link :to="'/elevator/' + scope.row.id" target="_blank">{{ scope.row.no }}</router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="regNo"
          :label="$t('elevator.registerCode')"
          width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="dtuCode" :label="$t('elevator.dtuCode')" width="150" align="center"></el-table-column>
        <el-table-column prop="nodeCode" :label="$t('elevator.nodeCode')" width="100" align="center"></el-table-column>
        <el-table-column prop="name" :label="$t('elevator.name')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('device.status')" align="center" width="80">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.onlineStatus">{{$t("device.online")}}</el-tag>
            <el-tag v-else type="info">{{$t("device.offline")}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.operation')" width="130" align="center" fixed="right">
          <template v-slot="scope">
            <el-button type="text" @click="pushElevatorInfo(scope.row)">物联推送</el-button>
            <el-button type="text" @click="pushRundata(scope.row)">运行数据</el-button>
          </template>
        </el-table-column>
      </vm-table>
    </el-main>
  </el-container>
</template>
<script>
  import VmTree from "../../components/VmTree.vue";
  import ElMain from "element-ui/packages/main/src/main.vue";

  const moduleName = "elevators";
  const moduleName_productType = "elevatorProductTypes";

  export default {
    components: { ElMain },
    data() {
      return {
        loading: false,
        showAside: false,
        search: {
          filter: "",
          productTypeId: "",
          integrationKey: "huzhou",
          maintComId: "",
          quantityOrganId: "",
          createComId: "",
          installComId: "",
          remakeComId: "",
          useUnitId: "",
          onlineStatus: "",
          inNetState: "",
          hasActivatedParts: "",
          leaveStartTime: "",
          leaveEndTime: "",
          installStartTime: "",
          installEndTime: "",
          inNetStartTime: "",
          inNetEndTime: "",
          useStartTime: "",
          useEndTime: "",
          districtId: "",
        },
        productTypeOptions: [],
        activatedPartsStatus: [
          { value: "false", label: this.$t("elevator.notActivated") },
          { value: "true", label: this.$t("elevator.activated") },
        ],
        onlineStatusOptions: [
          { value: 0, label: this.$t("device.offline") },
          { value: 1, label: this.$t("device.online") },
        ],
        inNetStateOptions: [
          { value: "false", label: this.$t("elevator.notJoined") },
          { value: "true", label: this.$t("elevator.joined") },
        ],
      };
    },
    mounted() {
      this.getList(1);
      this.getProductTypeOptions();
    },
    methods: {
      getList(pageIndex) {
        this.loading = true;
        this.$refs.vmTable.getList(pageIndex);
        this.loading = false;
      },
      pushElevatorInfo(row) {
        this.$http.post(window.config.integrationUrl +"huz/elev_api/push/elevatorInfo?regCode=" + row.regNo).then(res => {
          if (res) {
            this.$message.success(this.$t("elevator.tip.operateSuccess"));
          } else {
            this.$message.error(this.$t("elevator.tip.operateError"));
          }
        }).catch((error) => {
          this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
        });
      },
      pushRundata(row) {
        this.$http.post(window.config.integrationUrl +"huz/elev_api/push/runData?regCode=" + row.regNo).then(res => {
          if (res) {
            this.$message.success(this.$t("elevator.tip.operateSuccess"));
          } else {
            this.$message.error(this.$t("elevator.tip.operateError"));
          }
        }).catch((error) => {
          this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
        });
      },
      getProductTypeOptions() {
        this.$api.getList(moduleName_productType).then(response => {
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName };
            } else {
              item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn };
            }
            this.productTypeOptions.push(item);
          }
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      handleCollapse() {
        this.showAside = !this.showAside;
        if (this.showAside) {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 20px");
        } else {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 0px");
        }
      },
    },
  };
</script>
